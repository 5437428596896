import React from 'react'
import { useNavigate } from 'react-router-dom'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { textEllipsis } from 'utils/string_utils'
import { formatDateTimeToDateTimeBR, formatDateToDateBR, formatDateToDateBRPolish } from 'utils/date_utils'

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import { ReactComponent as MarketingIcon } from '../../assets/marketing.svg'
import { ReactComponent as NecessaryIcon } from '../../assets/necessary.svg'
import { ReactComponent as PreferencesIcon } from '../../assets/preferences.svg'
import { ReactComponent as StatisticsIcon } from '../../assets/statistics.svg'

export const DefaultTable = (props) => {
  const navigate = useNavigate()
  const {
    applyMB = false,
    cells,
    link,
    hideDelete,
    hideEdit,
    hideShow,
    id,
    onClick,
    onClickArchive,
    onClickDelete,
    onClickEdit,
    onClickShow,
    onClickStatus,
    onClickUnarchive,
    rows,
    showArchive = false,
    showNoDataLine = false,
    useOnClickDefault = false,
    userId
  } = props

  const displayData = (item, row, index) => {
    if (row.type === 'boolean') {
      return item[row.id] ? 'Sim' : 'Não'
    }
    if (row.type === 'actions') {
      return (
        <Table
          sx={{
            padding: '0px',
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                '& td': {
                  border: '0px',
                  padding: '0px',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  '& svg': {
                    fill: '#0039C5',
                    '&:nth-of-type(2)': {
                      marginLeft: '4px',
                    },
                    '&:nth-of-type(3)': {
                      marginLeft: '4px',
                      marginRight: '4px',
                    },
                  },
                  '& svg:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {!hideShow && (<InsertDriveFileOutlinedIcon
                  title="Visualizar"
                  onClick={() => {
                    if (onClickShow) {
                      onClickShow(item)
                    } else {
                      navigate(`/${link}/show/${item?._id?.toString()?? id}`)
                    }
                  }}
                />)}
                {!hideEdit && (<EditOutlinedIcon
                  title="Editar"
                  onClick={() => {
                    if (onClickEdit) {
                      onClickEdit(item)
                    } else {
                      navigate(`/${link}/edit/${item?._id?.toString() ?? id}`)
                    }
                  }}
                />)}
                {!hideDelete && (<DeleteOutlineOutlinedIcon
                  title="Remover"
                  onClick={() => onClickDelete(item)} />)}
                {(!!showArchive && !item?.archived) && (<ArchiveIcon
                  title="Arquivar"
                  onClick={() => onClickArchive(item)} />)}
                {(!!showArchive && item?.archived) && (<UnarchiveIcon
                  title="Desarquivar"
                  onClick={() => onClickUnarchive(item)} />)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
    if (row.type === 'actions-datamapping') {
      return (
        <Table
          sx={{
            padding: '0px',
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                '& td': {
                  border: '0px',
                  padding: '0px',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  '& svg': {
                    fill: '#0039C5',
                    '&:nth-of-type(2)': {
                      marginLeft: '8px',
                      marginRight: '8px',
                    },
                  },
                  '& svg:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {!hideShow && (<InsertDriveFileOutlinedIcon
                  title="Visualizar"
                  onClick={() => {
                    if (onClickShow) {
                      onClickShow(index)
                    } else {
                      navigate(`/${link}/show/${item?._id?.toString()?? id}`)
                    }
                  }}
                />)}
                {!hideEdit && (<EditOutlinedIcon
                  title="Editar"
                  onClick={() => {
                    if (onClickEdit) {
                      onClickEdit(index)
                    } else {
                      navigate(`/${link}/edit/${item?._id?.toString() ?? id}`)
                    }
                  }}
                />)}
                {!hideDelete && (<DeleteOutlineOutlinedIcon
                  title="Remover"
                  onClick={() => onClickDelete(item, index)} />)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
    if (row.type === 'actions-policy') {
      const canDelete = (policy) => {
        if (hideDelete) return false
        if (policy.policyVersions) {
          if (policy.policyVersions.find((policyVersion) => {
            return policyVersion.publishedDate != null
          })) {
            return false
          }
        }
        return true
      }

      const canChangeStatus = (policy) => {
        if (policy.lastPolicyVersionStatus !== 'Iniciado' && policy.lastPolicyVersionStatus !== undefined) {
          var hasPolicyVersionToChangeStatus = policy.policyVersions?.filter(policyVersion => policyVersion.status !== 'Iniciado' && policyVersion.status !== 'Publicado')
          if (hasPolicyVersionToChangeStatus.length > 0) {
            var policyVersion = hasPolicyVersionToChangeStatus[0]
            if (
              (policyVersion.status === 'Concluído' && policyVersion.reviewer._id.toString() === userId) ||
              (policyVersion.status === 'Revisado' && policyVersion.approver._id.toString() === userId) ||
              (policyVersion.status === 'Aprovado' && policyVersion.author._id.toString() === userId)
            ) {
              return true
            }
          }
        }
        return false
      }
      return (
        <Table
          sx={{
            padding: '0px',
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                '& td': {
                  border: '0px',
                  padding: '0px',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  '& svg': {
                    fill: '#0039C5',
                    '&:nth-of-type(2)': {
                      marginLeft: '8px',
                      marginRight: '8px',
                    },
                  },
                  '& svg:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {!hideShow && (<InsertDriveFileOutlinedIcon
                  onClick={() => navigate(`/${link}/show/${item?._id?.toString()}`)}
                />)}
                {canChangeStatus(item) && (<PublishedWithChangesOutlinedIcon
                  onClick={() => navigate(`/${link}/checklist/${item?._id?.toString()}/versions/${item.policyVersions.findIndex(policyVersion => policyVersion.status !== 'Iniciado' && policyVersion.status !== 'Publicado')}`)}
                />)}
                <EditOutlinedIcon
                  onClick={() => navigate(`/${link}/edit/${item?._id?.toString()}`)}
                />
                {canDelete(item) && (<DeleteOutlineOutlinedIcon onClick={() => onClickDelete(item)} />)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
    if (row.type === 'actions-policy-version') {
      const canEdit = (policyVersion) => {
        if (policyVersion.approvedDate) return false
        if (policyVersion.publishedDate) return false
        return true
      }
      const canDelete = (policyVersion) => {
        if (hideDelete) return false
        if (policyVersion.publishedDate != null) return false
        return true
      }
      const canChangeStatus = (policyVersion) => {
        if (policyVersion.status !== 'Iniciado' &&
        policyVersion.status !== 'Publicado') {
          if ((policyVersion.status === 'Concluído' && policyVersion.reviewer._id.toString() === userId) ||
          (policyVersion.status === 'Revisado' && policyVersion.approver._id.toString() === userId) ||
          (policyVersion.status === 'Aprovado' && policyVersion.author._id.toString() === userId)) {
            return true
          }
        }
        return false
      }
      const canShowAccepts = (policyVersion) => {
        return policyVersion.status === 'Publicado' && !!id
      }
      const canShow = (notHide, policyVersion) => {
        return notHide && !!id
      }
      return (
        <Table
          sx={{
            padding: '0px',
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                '& td': {
                  border: '0px',
                  padding: '0px',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  '& svg': {
                    fill: '#0039C5',
                    '&:nth-of-type(2)': {
                      marginLeft: '8px',
                      marginRight: '8px',
                    },
                  },
                  '& svg:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {canShowAccepts(item) && (<PreviewOutlinedIcon
                  title={"Visualizar aceites da política"}
                  onClick={() => navigate(`/${link}/show/${id}/versions/${index}/accepts`)}
                />)}
                {canShow(!hideShow, item) && (<InsertDriveFileOutlinedIcon
                  title={"Visualizar versão da política"}
                  onClick={() => navigate(`/${link}/show/${item?._id?.toString()?? id}/versions/${index}`)}
                />)}
                {canChangeStatus(item) && (<PublishedWithChangesOutlinedIcon
                  title={"Ir para aprovação da versão da política"}
                  onClick={() => navigate(`/${link}/checklist/${id}/versions/${index}`)}
                />)}
                {canEdit(item) && (<EditOutlinedIcon
                  title={"Editar versão da política"}
                  onClick={() => onClickEdit(index)}
                />)}
                {canDelete(item) && (<DeleteOutlineOutlinedIcon
                  title={"Remover política"}
                  onClick={() => onClickDelete(item, index)}
                />)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
    if (row.type === 'actions-redirect') {
      return (
        <Table
          sx={{
            padding: '0px',
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                '& td': {
                  border: '0px',
                  padding: '0px',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  '& svg': {
                    fill: '#0039C5',
                    '&:nth-of-type(2)': {
                      marginLeft: '8px',
                      marginRight: '8px',
                    },
                  },
                  '& svg:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {!hideShow && (<InsertDriveFileOutlinedIcon
                  onClick={() => navigate(`/${link}/show/${item?._id?.toString()?? id}`)}
                />)}
                <EditOutlinedIcon
                  onClick={() => navigate(`/${link}/edit/${item?._id?.toString() ?? id}`)}
                />
                {!hideDelete && (<DeleteOutlineOutlinedIcon onClick={() => onClickDelete(item)} />)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
    if (row.type === 'actions-status') {
      return (
        <Table
          sx={{
            padding: '0px',
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                '& td': {
                  border: '0px',
                  padding: '0px',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  '& svg': {
                    fill: '#0039C5',
                    '&:nth-of-type(2)': {
                      marginLeft: '8px',
                      marginRight: '8px',
                    },
                  },
                  '& svg:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {!hideShow && (<InsertDriveFileOutlinedIcon
                  title="Visualizar"
                  onClick={() => {
                    if (onClickShow) {
                      onClickShow(item)
                    } else {
                      navigate(`/${link}/show/${item?._id?.toString()?? id}`)
                    }
                  }}
                />)}
                {!hideEdit && (<EditOutlinedIcon
                  title="Editar"
                  onClick={() => {
                    if (onClickEdit) {
                      onClickEdit(item)
                    } else {
                      navigate(`/${link}/edit/${item?._id?.toString() ?? id}`)
                    }
                  }}
                />)}
                {!hideDelete && item?.active && (<DeleteOutlineOutlinedIcon
                  title="Remover"
                  onClick={() => onClickDelete(item)} />)}
                {!item?.active && (<SyncAltIcon
                  title="Habilitar"
                  onClick={() => onClickStatus(item)} />)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
    if (row.type === 'object') {
      const fields = row.id.split('.')
      return item[fields[0]][fields[1]]
    }
    if (row.type === 'date') {
      return formatDateToDateBRPolish(item[row.id])
    }
    if (row.type === 'datebr') {
      return formatDateToDateBR(item[row.id])
    }
    if (row.type === 'datetime') {
      return formatDateTimeToDateTimeBR(item[row.id], true)
    }
    if (row.type === 'time-expires') {
      if (item[row.id] === -1) {
        return 'Session';
      } else if (item[row.id] < 24) {
        return `${item[row.id]} Hora(s)`
      } else {
        const days = item[row.id] / 24;
        return `${days.toFixed(0)} Dias`
      }
    }
    if (row.type === 'category-cookie') {
      switch(item[row.id]) {
        case 'Necessary':
          return 'Necessária';
        case 'Statistics':
          return 'Estatisticas';
        case 'Preferences':
          return 'Preferências';
        case 'Unclassified':
          return 'Não Classificada'
        default:
          return 'Marketing';
      }
    }
    if (row.type === 'consent-categories') {
      return (
        <Table
          sx={{
            padding: '0px',
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                '& td': {
                  border: '0px',
                  padding: '0px',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <div style={{
                  display: 'flex',
                  height: '22px',
                  width: '22px',
                  justifyContent: 'center',
                  backgroundColor: item[row.id]?.necessary?"#d1fae5":"#f3f4f6",
                  borderRadius: '50%',
                  marginInlineEnd: '1px'}}>
                  <NecessaryIcon title="Necesário" width="14px" color={item[row.id]?.necessary?"#065f46":"#9ca3af"} />
                </div>
                <div style={{
                  display: 'flex',
                  height: '22px',
                  width: '22px',
                  justifyContent: 'center',
                  backgroundColor: item[row.id]?.preferences?"#d1fae5":"#f3f4f6",
                  borderRadius: '50%',
                  marginInlineEnd: '1px'}}>
                  <PreferencesIcon title="Preferencias" width="14px" color={item[row.id]?.preferences?"#065f46":"#9ca3af"} />
                </div>
                <div style={{
                  display: 'flex',
                  height: '22px',
                  width: '22px',
                  justifyContent: 'center',
                  backgroundColor: item[row.id]?.marketing?"#d1fae5":"#f3f4f6",
                  borderRadius: '50%',
                  marginInlineEnd: '1px'}}>
                  <MarketingIcon title="Marketing" width="14px" color={item[row.id]?.marketing?"#065f46":"#9ca3af"} />
                </div>
                <div style={{
                  display: 'flex',
                  height: '22px',
                  width: '22px',
                  justifyContent: 'center',
                  backgroundColor: item[row.id]?.statistics?"#d1fae5":"#f3f4f6",
                  borderRadius: '50%'}}>
                  <StatisticsIcon title="Statisticas" width="14px" color={item[row.id]?.statistics?"#065f46":"#9ca3af"} />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
    if (row.type === 'consent-status') {
      return item[row.id] === 'accepted'? 'Aceitado' : 'Rejeitado'
    }
    if (row.type === 'array-object') {
      const fields = row.id.split('.')
      const content = item[fields[0]].findLast(field => field[fields[1]])
      return content ? content[fields[1]] : ''
    }
    if (row.type === 'array-object-datetime') {
      const fields = row.id.split('.')
      const content = item[fields[0]].findLast(field => field[fields[1]])
      return formatDateTimeToDateTimeBR(content?.[fields[1]], true)
    }
    if(row.type === 'text' && item[row.id] === 'null') {
      return ""
    }
    return item[row.id]
  }

  const noData = () => {
    if (!cells || cells.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, marginBottom: noData() || applyMB ? '16px' : 0 }} aria-label="simple table">
        <TableHead>
          <TableRow key={'table-row-header'}>
            {rows.map((row) => (
              <TableCell key={`thrc-${row.value}`}>{row.value}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {cells &&
            cells.map((item, index) => (
              <TableRow
                onClick={() => {
                  if (useOnClickDefault && onClick) {
                    onClick(item)
                  }
                }}
                key={`tr-${item?._id?.toString()??index}`}
                sx={{
                  '& > tbody > tr > td:last-child, & > thead > tr > th:last-child': {
                    border: 0,
                    textAlign: 'center',
                  },
                }}
              >
                {rows.map((row) => (
                  <TableCell
                    key={`${row.id}`}
                    title={!row.type.includes('actions') ? displayData(item, row) : ''}
                    style={row.type.includes('actions') && !row?.minWidth ? {minWidth: '120px'} : (
                      (row.type.includes('actions') && !!row?.minWidth) || row?.minWidth) ? { minWidth: `${row.minWidth}` } : {}}>
                    {!row.type.includes('actions') ? textEllipsis(
                        displayData(item, row),
                        row?.maxSize || 60
                      ) : displayData(item, row, index)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {showNoDataLine && noData() && (
              <TableRow
                onClick={() => {}}
                key={`tr-no-data-line`}
                sx={{
                  border: 0,
                  textAlign: 'center',
                }}
              >
                <TableCell
                  key={`cell-no-data-line`}
                  title={"Não há dados"}
                  sx={{
                    textAlign: 'center',
                  }}
                  colspan={rows.length}>
                  Não há dados
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
