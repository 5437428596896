import { useMutation, useQuery } from 'react-query';
import { MainContent, TabContainer, EmptyInfo, ScanBtn } from './styles';
import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import { canScan, scanBySlug } from 'services/asm-service';
import Swal from 'sweetalert2';
import { NoData } from 'Components/NoData';
import { TabAsset } from './TabAsset';
import { TabCert } from './TabCert';
import { TabVulnerability } from './TabVulnerability';

const TabPanel = props => {
  const { children, value, index, id, ariaLabel, ...other } = props;

  return (
    <TabContainer role="tabpanel" hidden={value !== index} id={id} aria-labelledby={ariaLabel} {...other}>
      {value === index && <>{children}</>}
    </TabContainer>
  );
};

export const AsmDashboard = () => {
  const [tabPosition, setTabPosition] = useState(0);
  const selectedTenant = useSelector(useAuthTenant);

  const { data: cantScan, isLoading: canScanLoading } = useQuery(['getCanScan'], async () => {
    if (selectedTenant.slug === undefined) return true;

    const response = await canScan(selectedTenant.slug);
    if (!!response.data) {
      return !response.data.canScan;
    }
    return true;
  });

  const mutation = useMutation(
    async () => {
      const response = await scanBySlug(selectedTenant.slug);
      // const response = await scanBySlug(selectedTenant.slug);
      if (response.status === 200) {
        Swal.fire(
          'Iniciado!',
          'O scan foi iniciado em todos seus domínios cadastrados. Por favor, aguarde...',
          'success',
        );
        setButtonDisabled(true);
      }
    },
    {
      onError: _ => {
        Swal.fire({
          icon: 'info',
          title: 'Seus domínios já estão sendo analisados',
          text: 'Aguarde o término do processo atual.',
        });
      },
      onSuccess: _ => {},
    },
  );

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  return (
    <div className="mt-3 px-3">
      {!canScanLoading && !cantScan && (
        <EmptyInfo>
          <NoData description={'Nenhum domínio foi analisado.'} />
          <p>Clique no botão abaixo para iniciar o scan em seus domínios cadastrados.</p>
          <ScanBtn
            disabled={cantScan && isButtonDisabled}
            onClick={() => {
              Swal.fire({
                title: 'Você tem certeza que deseja começar o Scan em todos os domínios cadastrados?',
                text: 'O período de análise é em média de 1 dia.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, começar scan!',
                cancelButtonText: 'Cancelar',
              }).then(result => {
                if (result.isConfirmed) {
                  mutation.mutate();
                }
              });
            }}
          >
            Scanear
          </ScanBtn>
        </EmptyInfo>
      )}
      {cantScan && (
        <MainContent>
          <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', marginBottom: '24px' }}>
            <Tabs value={tabPosition} onChange={(_, newValue) => setTabPosition(newValue)}>
              <Tab
                label="Ativos"
                id="tab-assets"
                aria-controls="tabpanel-assets"
                style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
              />
              <Tab
                label="Certificados"
                id="tab-certificates"
                aria-controls="tabpanel-certificates"
                style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
              />
              <Tab
                label="Vulnerabilidades"
                id="tab-vulnerabilities"
                aria-controls="tabpanel-vulnerabilities"
                style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
              />
            </Tabs>
          </Box>
          <TabPanel value={tabPosition} index={0} id="tabpanel-assets" ariaLabel="tab-assets">
            <TabAsset />
          </TabPanel>
          <TabPanel value={tabPosition} index={1} id="tabpanel-certificates" ariaLabel="tab-certificates">
            <TabCert />
          </TabPanel>
          <TabPanel value={tabPosition} index={2} id="tabpanel-vulnerabilities" ariaLabel="tab-vulnerabilities">
            <TabVulnerability />
          </TabPanel>
        </MainContent>
      )}
    </div>
  );
};
