import { Box, Chip, Tab, Tabs } from '@mui/material';
import { Container } from 'Components/Container';
import { Column } from 'Components/Grid';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnEdit, LinkEdit, RowExhibition } from './styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getSystemById } from 'services/systems-service';
import { PageLoaderShow } from 'Components/PageLoader/Show';

const TabPanel = props => {
  const { children, value, index, id, ariaLabel, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={id} aria-labelledby={ariaLabel} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const SystemsShow = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabPosition, setTabPosition] = useState(0);

  const {
    isLoading,
    error,
    data: system,
  } = useQuery('getSystemById', async () => {
    const dataSystem = await getSystemById(id);

    return dataSystem.status === 200 ? dataSystem.data : null;
  });
  const onClickEdit = () => {
    navigate('/systems/edit/' + id);
  };

  return (
    <>
      {!isLoading && !error && (
        <Container title={`${system?.name}`} breadcrumb={`Mapeamento / Sistemas / ${system?.name}`} linkPage="/systems">
          <Box sx={{ width: '100%' }} className="container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabPosition}
                onChange={(data, newValue) => setTabPosition(newValue)}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Detalhes"
                  id="tab-details"
                  aria-controls="tabpanel-details"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Vínculos"
                  id="tab-links"
                  aria-controls="tabpanel-links"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
              </Tabs>
            </Box>
            <TabPanel value={tabPosition} index={0} id="tabpanel-details" ariaLabel="tab-details">
              <RowExhibition>
                <Column xs={3}>Nome</Column>
                <Column>{system.name}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Criticidade</Column>
                <Column>{system.criticity}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Responsável</Column>
                <Column>{system.user}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>SaaS</Column>
                <Column>{system.saas ? 'Sim' : 'Não'}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Desenvolvido internamente</Column>
                <Column>{system.developedInHouse ? 'Sim' : 'Não'}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>TAGs</Column>
                <Column>
                  {system.tags?.map(tag => (
                    <Chip
                      key={tag}
                      label={tag}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Descrição</Column>
                <Column>{system.description}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Banco de dados</Column>
                <Column>{system.dataBase}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
            <TabPanel value={tabPosition} index={1} id="tabpanel-links" ariaLabel="tab-links">
              <RowExhibition>
                <Column xs={3}>Areas</Column>
                <Column>
                  {system?.areas?.map(area => (
                    <Chip
                      key={area.name}
                      label={area.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                        display: area.archived ? 'none' : '',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Processos</Column>
                <Column>
                  {system?.processes?.map(process => (
                    <Chip
                      key={process.name}
                      label={process.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                        display: process.archived ? 'none' : '',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Ativos</Column>
                <Column>
                  {system?.assets?.map(asset => (
                    <Chip
                      key={asset.name}
                      label={asset.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                        display: asset.archived ? 'none' : '',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
