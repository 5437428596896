import { backendServicePublic } from "services"

export const getProductsCheckout = async () => {
    return await backendServicePublic.get(`/checkout/products`)
}

export const getPricesByProductCheckout = async (productId) => {
    return await backendServicePublic.get(`/checkout/prices/by-product/${productId}`)
}

export const createCheckoutSession = async (priceId, slug) => {
    return await backendServicePublic.post(`/checkout/create-session`, {
        priceId,
        slug
    })
}