import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { ButtonForm, CustomTextField } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getAreasShort } from 'services/areas-service';
import { getProcessesShort } from 'services/processes-service';

export const GenerateNewReportRopa = ({
  handleComplete,
  register,
  control,
  handleSubmit,
  getValues,
  errors,
  watch,
  selectedTenant,
}) => {
  let scope = watch('scope');
  const { data: areas } = useQuery(['getAreas', scope], async () => {
    if (selectedTenant.slug === undefined || scope === 'Empresa' || scope === 'Processo') return [];
    const result = await getAreasShort(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });
  const { data: processes } = useQuery(['getProcesses', scope], async () => {
    if (selectedTenant.slug === undefined || scope === 'Empresa' || scope === 'Area') return [];
    const result = await getProcessesShort(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });
  const navigate = useNavigate();
  const onSubmit = () => {
    handleComplete();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={12} sm={12} md={12} className="d-flex">
          <CustomTextField
            {...register('name', { required: 'Nome é obrigatório' })}
            label="Nome do Relatório"
            autoFocus
            error={errors?.name ? true : false}
            sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
            helperText={errors?.name ? errors.name.message : null}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} sm={6} md={6} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues('scope') !== '';
              },
              required: 'Escopo é obrigatório',
            }}
            name="scope"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ marginBottom: '24px' }} error={errors?.scope ? true : false}>
                <InputLabel id="scope-label" shrink={true}>
                  Escopo
                </InputLabel>
                <Select labelId="scope-label" id="scope" value={value} notched label="Escopo" onChange={onChange}>
                  <MenuItem value="" key={-1}>
                    Selecione um escopo
                  </MenuItem>
                  {['Empresa', 'Área', 'Processo', 'Sumário Executivo'].map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.scope ? errors.scope.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
        <Column xs={12} sm={6} md={6} className="d-flex">
          {scope === 'Área' && !!areas && areas.length > 0 && (
            <Controller
              control={control}
              name="areaId"
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                  <InputLabel id="areaId-label" shrink={true}>
                    Área
                  </InputLabel>
                  <Select labelId="areaId-label" id="areaId" value={value} notched label="Área" onChange={onChange}>
                    <MenuItem value="" key={-1}>
                      Selecione uma área
                    </MenuItem>
                    {areas.map((item, index) => (
                      <MenuItem
                        sx={{
                          display: item?.archived ? 'none' : '',
                        }}
                        value={item._id}
                        key={index}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
          {scope === 'Processo' && !!processes && processes.length > 0 && (
            <Controller
              control={control}
              name="processId"
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                  <InputLabel id="processId-label" shrink={true}>
                    Processo
                  </InputLabel>
                  <Select
                    labelId="processId-label"
                    id="processId"
                    value={value}
                    notched
                    label="Processo"
                    onChange={onChange}
                  >
                    <MenuItem value="" key={-1}>
                      Selecione um processo
                    </MenuItem>
                    {processes.map((item, index) => (
                      <MenuItem
                        sx={{
                          display: item?.archived ? 'none' : '',
                        }}
                        value={item._id}
                        key={index}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={() => navigate('/reports/ropa')}></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label="Salvar" variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
