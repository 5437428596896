import { archiveSystem, deleteSystem, unarchiveSystem } from "services/systems-service";

export const callDeleteAction = async (itemToDelete, onClose, refetch, setRefetch, SwalReact) => {
  const result = await deleteSystem(itemToDelete?._id);
  if (result.status === 200 || result.status === 204) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Sistema removido com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar remover o Sistema!');
  }
};

export const callArchiveAction = async (itemToArchive, onClose, refetch, setRefetch, SwalReact) => {
  const result = await archiveSystem(itemToArchive?._id);
  if (result.status === 200) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Sistema arquivado com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar arquivar o Sistema!');
  }
};

export const callUnarchiveAction = async (itemToUnarchive, onClose, refetch, setRefetch, SwalReact) => {
  const result = await unarchiveSystem(itemToUnarchive?._id);
  if (result.status === 200) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Sistema desarquivado com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar desarquivar o Sistema!');
  }
};