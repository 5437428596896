import { backendServicePublic } from "services"

export const sendVerifyCode = async (email, companyId) => {
  return await backendServicePublic.post(`/user/auth/send-verify-code`, {
    email,
    companyId
  })
}

export const userAuth = async (email, code, companyKey) => {
  return await backendServicePublic.post(`/user/auth`, {
    code,
    email,
    companyKey
  })
}