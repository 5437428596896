import { Card } from "@mui/material";
import styled from "styled-components";


const c1 = "#72C8CC"

export const CardCompany = styled(Card)`
    min-height: 106px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
`
export const TitleCompany = styled.h3`
    font-family: 'Oxygen';
    font-weight: 400;
    font-size: 24px;
    color: #0039C5;
    margin-block-start: 25px;
    margin-block-end: 9px;
`

export const CardCounter = styled(Card)`
    min-width: 198px;
    max-width: 224px;
    height: 180px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
    padding: 16px;
`
export const LogoCardCounter = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
`
export const ContainerLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #537DE4;
    box-shadow: 0px 6px 20px #EFF1F7;
    border-radius: 12px;
`
export const ContentCardCounter = styled.div`
    height: 54px;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    // line-height: 54px;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
    color: #0039C5;
    justify-content: space-between;
    margin-top: 32px
`
export const TitleCardCounter = styled.div`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    display: flex;
    font-feature-settings: 'liga' off;
    color: #6B6664;
    margin-block-start: 10px;
    height: 20px;
`

export const TitleContent = styled.h4`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #0039C5;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
`
export const CardPendingPolicies = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 10px;
    height: 338px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
`
export const CardStatusControls = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    height: 338px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
`

export const ItemPendingPolicy = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    &:hover {
        cursor: pointer;
    }
`

export const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 2rem 0;
    flex-direction: column;
`
export const TopGraph = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`
export const BlockList = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
`

export const SingleBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    width: 30%;
    justify-content: space-around;
`

export const InfoBlock = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 370px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 16px !important;
    border: 1px solid #F2F2F2;
    padding: 16px;
    margin-bottom: 40px;
    gap: 5px;
`

export const InfoCDNBlock = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 500px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 16px !important;
    border: 1px solid #F2F2F2;
    padding: 16px;
    margin-bottom: 40px;
    gap: 5px;
`

export const InfoCertBlock = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 780px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 16px !important;
    border: 1px solid #F2F2F2;
    padding: 16px;
    margin-bottom: 40px;
    gap: 5px;
`

export const InfoVulBlock = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 680px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 16px !important;
    border: 1px solid #F2F2F2;
    padding: 16px;
    margin-bottom: 40px;
    gap: 5px;
`
export const InfoVulFullBlock = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 91%;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 16px !important;
    border: 1px solid #F2F2F2;
    padding: 16px;
    margin-bottom: 40px;
    gap: 5px;
`

export const BlockTitle = styled.h3`
    font-family: 'Oxygen';
    font-weight: 400;
    font-size: 24px;
    color: #0039C5;
    margin-block-start: 0px;
    margin-block-end: 8px;
    text-align: center;
`

export const BlockContent = styled.div`
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 20px;
    overflow: auto;
    padding-bottom: 10px;

`
export const BlockContentTable = styled.div`
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 20px;
    overflow: auto;
    padding-bottom: 10px;

`
export const TableLane = styled.div`
    display: flex;
    justify-content: space-around;;
    text-align: center;
    align-items: center;
`

export const TableTitle = styled.p`
    width: 7rem;
`

export const TableTxt = styled.p`

    text-overflow: ellipsis;

`

export const EmptyInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 100%;
`

export const SideCards = styled.div`
    width: 30%;
    padding: 0 2.5rem;
`
export const MainCards = styled.div`
    width: 90%;
    height: 100%;
    padding: 0 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: space-around;
`
export const MainCardsLayout = styled.div`
    display: flex;
    flex-direction: column;
    aling-items: center;
    gap: 20px;
    padding-bottom: 20px;
`

export const MainCardsTitle = styled.h3`
    font-family: 'Oxygen';
    font-weight: Bold;
    font-size: 4.5rem;;
    margin-block-start: 25px;
    margin-block-end: 9px;
    text-align: center;
    color: #000000AA;
`
export const MainCardsSubtitle = styled.p`
    font-family: 'Oxygen';
    font-weight: 400;
    font-size: 1rem;;
    padding: 0;
    margin-block-start: 25px;
    margin-block-end: 9px;
    text-align: center;
    color: #111111;
`

export const MainBallLine = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`

export const PointsBallG = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
    background-color: #A6D997;
    padding: 8px 10px;
    border-radius: 100%;
    min-width: 50px;
    min-height: 50px;
`

export const PointsBallGMin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    background-color: #A6D997;
    padding: 8px 10px;
    border-radius: 100%;
    min-width: 20px;
    min-height: 20px;
`
export const PointsBallY = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    background-color: #FFBB4F;
    padding: 8px 10px;
    border-radius: 100%;
    min-width: 50px;
    min-height: 50px;

`

export const PointsBallYMin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #FFBB4F;
    padding: 8px 10px;
    border-radius: 100%;
    min-width: 20px;
    min-height: 20px;
`

export const PointsBallRMin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-size: 24px;
    color: #ffffff;
    background-color: #FF0000;
    padding: 8px 10px;
    border-radius: 100%;
    min-width: 20px;
    min-height: 20px;
`

export const PointsBallR = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-size: 24px;
    color: #ffffff;
    background-color: #FF0000;
    padding: 8px 10px;
    border-radius: 100%;
    min-width: 50px;
    min-height: 50px;
`

export const BallsBlock = styled.div`
    display: flex;
    flex-direction: column;

    gap: 2rem;
    padding: 1rem 2rem;
`

export const BallsLine = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

`

export const CompareBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const CompareBallsLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
`

export const ScanBtn = styled.button`
    display: flex;

    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    border: none;
    background-color: #0039C5;
    color: #ffffff;
    padding: 8px 10px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 16px;
    &:hover {
        cursor: pointer;
        background-color: #0039C5;
        color: #ffffff;
    }
`

export const ExpandBtn = styled.p`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Oxygen';
  :hover {
    cursor: pointer;
  }
`
export const ScannedLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
`

export const PieBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15rem;
`

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: -webkit-fill-available
`

export const MainContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

`

export const SideLog = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    background-color: rgb(230 230 230);
    border-radius: 50px 0 0 50px;
    padding: 2rem;
    gap: 20px;
`

export const LogTitle = styled.h3`
    font-family: 'Oxygen';
    font-weight: 400;
    font-size: 24px;
    color: #0039C5;
    margin-block-start: 25px;
    margin-block-end: 9px;
    text-align: center;
`
export const LogContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
`

export const LogCard = styled(Card)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    gap: 10px;
    height: 100px;
    width: 90%;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 12px !important;
    border-color: #EFF1F7 !important;
`

export const LogCardTitle = styled.h4`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
`

export const LogScore = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
    background-color: ${c1};
    padding: 8px 10px;
    border-radius: 16px;
}
`

export const LogArrow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
`

export const CardContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-width: 60%;
    justify-content: space-between;
`