import { backendService } from "services"

export const getSystems = async (tenant) => {
  return await backendService.get(`/mapping/system?companyKey=${tenant}`)
}

export const getSystemsPaged = async (tenant, page, perPage) => {
  return await backendService.get(`/mapping/system/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getSystemsShort = async (tenant) => {
  return await backendService.get(`/mapping/system/short-data?companyKey=${tenant}`)
}

export const getSystemsShortPaged = async (tenant, page, perPage, queryContent) => {
  return await backendService.get(`/mapping/system/short-data-paged?companyKey=${tenant}&page=${page}&perPage=${perPage}${queryContent}`)
}

export const getSystemsNames = async (tenant) => {
  return await backendService.get(`/mapping/system/names?companyKey=${tenant}`)
}

export const getSystemById = async (id) => {
  return await backendService.get(`/mapping/system/${id}`)
}

export const getSystemBoundsById = async (id) => {
  return await backendService.get(`/mapping/system/${id}/bounds`)
}

export const addSystem = async (system) => {
  return await backendService.post('/mapping/system', system)
}

export const editSystem = async (id, system) => {
  return await backendService.put(`/mapping/system/${id}`, system)
}

export const deleteSystem = async (id) => {
  return await backendService.delete(`/mapping/system/${id}`)
}

export const archiveSystem = async (id) => {
  return await backendService.post(`/mapping/system/archive/${id}`)
}

export const unarchiveSystem = async (id) => {
  return await backendService.post(`/mapping/system/unarchive/${id}`)
}