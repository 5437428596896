import { useState } from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import { getAsmAssetPaged, getCdnList, getWafList } from 'services/asm-service';
import { useAuthTenant } from 'store/auth';
import { BlockTitle, InfoCDNBlock } from '../styles';
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { DefaultTable } from 'Components/DefaultTable';
import { Pagination, Stack } from '@mui/material';
import { tableHeaderAsmAsset } from './table-header-asm-asset';
import { Shimmer } from 'Components/PageLoader/styles';
import { PageLoaderList } from 'Components/PageLoader/List';

export const TabAsset = () => {
  const [page, setPage] = useState(1);
  const selectedTenant = useSelector(useAuthTenant);

  const queries = useQueries([
    {
      queryKey: ['getCdnList'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return null;

        const response = await getCdnList(selectedTenant.slug);

        if (response?.data && response?.data?.cdns) {
          return response?.data?.cdns;
        }
        return [];
      },
    },
    {
      queryKey: ['getWafList'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return null;

        const response = await getWafList(selectedTenant.slug);

        if (response?.data && response?.data?.wafs) {
          return response?.data?.wafs;
        }
        return [];
      },
    },
    {
      queryKey: ['getAsmAssetPaged', page],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getAsmAssetPaged(selectedTenant.slug, page, 6);

        if (result?.data?.data.length > 0) {
          return result?.data;
        }
        return {
          data: [],
          page,
          totalPages: 0,
        };
      },
    },
  ]);

  const [
    {
      data: cdns,
      isLoading: cdnLoading,
      // isError: cdnError
    },
    {
      data: wafs,
      isLoading: wafLoading,
      // isError: wafError
    },
    {
      data: pagedAsmAsset,
      isLoading: asmAssetLoading,
      // isError: asmAssetError
    },
  ] = queries;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, qt } = payload[0].payload;
      return (
        <div style={{ backgroundColor: '#ffffff', padding: '5px', border: '1px solid #ccc' }}>
          <p>{`${name}: ${qt}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <InfoCDNBlock className={'mr-3'}>
        {!cdnLoading && (
          <>
            <BlockTitle>CDN</BlockTitle>
            <ResponsiveContainer width="100%" height="100%" minHeight={200} minWidth={500}>
              <BarChart
                layout={'vertical'}
                width={520}
                height={260}
                data={cdns}
                margin={{
                  top: 0,
                  right: 16,
                  left: 0,
                  bottom: 0,
                }}
                maxBarSize={cdns?.reduce((max, obj) => (obj.qt > max.qt ? obj : max), [0])[0].qt}
              >
                <XAxis type="number" domain={[0, 'dataMax + 1']} tick={false} axisLine={false} />
                <YAxis dataKey={'name'} type="category" width={100} />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" />
                <Bar name={'CDN'} dataKey="qt" barSize={24} fill="rgb(250,204,21)" />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
        {cdnLoading && <Shimmer style={{ height: '250px', width: '500px' }}></Shimmer>}
      </InfoCDNBlock>
      <InfoCDNBlock>
        {!wafLoading && (
          <>
            <BlockTitle>WAF</BlockTitle>
            <ResponsiveContainer width="100%" height="100%" minHeight={200} minWidth={500}>
              <BarChart
                layout={'vertical'}
                width={520}
                height={260}
                data={wafs}
                margin={{
                  top: 0,
                  right: 16,
                  left: 0,
                  bottom: 0,
                }}
                maxBarSize={wafs?.reduce((max, obj) => (obj.qt > max.qt ? obj : max), [0])[0].qt}
              >
                <XAxis type="number" domain={[0, 'dataMax + 1']} tick={false} axisLine={false} />
                <YAxis dataKey={'name'} type="category" width={100} />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" />
                <Bar name={'WAF'} dataKey="qt" barSize={24} fill="rgb(202,138,4)" />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
        {wafLoading && <Shimmer style={{ height: '250px', width: '500px' }}></Shimmer>}
      </InfoCDNBlock>
      {!asmAssetLoading && (
        <>
          <DefaultTable
            rows={tableHeaderAsmAsset}
            cells={pagedAsmAsset?.data || []}
            link=""
            showNoDataLine
            applyMB={pagedAsmAsset?.totalPages < 1}
          />
          {pagedAsmAsset?.totalPages > 0 && (
            <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginBlock: '16px', marginInline: 'auto' }}>
              <Pagination
                count={pagedAsmAsset.totalPages}
                onChange={(_, selectedPage) => {
                  setPage(selectedPage);
                }}
                color="primary"
                page={page}
                showFirstButton
                showLastButton
              />
            </Stack>
          )}
        </>
      )}
      {asmAssetLoading && <PageLoaderList />}
    </>
  );
};
