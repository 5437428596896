export const tableHeaderAsmCert = [
  {
    id: 'asset',
    type: 'text',
    value: 'Ativo',
  },
  {
    id: 'dns_names',
    type: 'text',
    value: 'DNS Nomes',
    maxSize: 32
  },
  {
    id: 'issuer',
    type: 'text',
    value: 'Emissor',
    minWidth: '180px'
  },
  {
    id: 'pubkey_sha256',
    type: 'text',
    value: 'Chave Publica',
    maxSize: 26
  },
  {
    id: 'valid_till',
    type: 'datetime',
    value: 'Válido até',
  },
];