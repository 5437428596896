import { archiveArea, deleteArea, unarchiveArea } from "services/areas-service";

export const callDeleteAction = async (itemToDelete, onClose, refetch, setRefetch, SwalReact) => {
  const result = await deleteArea(itemToDelete?._id);
  if (result.status === 204) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Área removida com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar remover a Área!');
  }
};

export const callArchiveAction = async (itemToArchive, onClose, refetch, setRefetch, SwalReact) => {
  const result = await archiveArea(itemToArchive?._id);
  if (result.status === 200) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Área arquivada com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar arquivar a Área!');
  }
};

export const callUnarchiveAction = async (itemToUnarchive, onClose, refetch, setRefetch, SwalReact) => {
  const result = await unarchiveArea(itemToUnarchive?._id);
  if (result.status === 200) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Área desarquivada com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar desarquivar a Área!');
  }
};
