export const tableHeaderAsmAsset = [
  {
    id: 'asset_type',
    type: 'text',
    value: 'Tipo',
  },
  {
    id: 'asset',
    type: 'text',
    value: 'Ativo',
  },
  {
    id: 'ports',
    type: 'text',
    value: 'Portas',
    minWidth: '105px'
  },
  {
    id: 'cdn_names',
    type: 'text',
    value: 'CDN',
    minWidth: '105px'
  },
  {
    id: 'technologies',
    type: 'text',
    value: 'Tecnologias',
    maxSize: 38,
    minWidth: '190px'
  },
  {
    id: 'waf',
    type: 'text',
    value: 'WAF',
    minWidth: '105px'
  },
  {
    id: 'webserver',
    type: 'text',
    value: 'Webserver',
    maxSize: 38,
    minWidth: '170px'
  },
  {
    id: 'source',
    type: 'text',
    value: 'Fonte'
  },
];