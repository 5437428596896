import { archiveAsset, deleteAsset, unarchiveAsset } from 'services/assets-service';

export const callDeleteAction = async (itemToDelete, onClose, refetch, setRefetch, SwalReact) => {
  const result = await deleteAsset(itemToDelete?._id);
  if (result.status === 204) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Ativo removido com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar remover o Ativo!');
  }
};

export const callArchiveAction = async (itemToArchive, onClose, refetch, setRefetch, SwalReact) => {
  const result = await archiveAsset(itemToArchive?._id);
  if (result.status === 200) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Ativo arquivado com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar arquivar o Ativo!');
  }
};

export const callUnarchiveAction = async (itemToUnarchive, onClose, refetch, setRefetch, SwalReact) => {
  const result = await unarchiveAsset(itemToUnarchive?._id);
  if (result.status === 200) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Ativo desarquivado com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar desarquivar o Ativo!');
  }
};
