

export const tableHeaderAsmVul = [
  {
    id: 'title',
    type: 'text',
    value: 'Título',
  },
  {
    id: 'host',
    type: 'text',
    value: 'Host',
  },
  {
    id: 'description',
    type: 'text',
    value: 'Descrição',
  },
  {
    id: 'severity',
    type: 'text',
    value: 'Severidade',
  },
  {
    id: 'status',
    type: 'text',
    value: 'Status',
  },
]