import { backendService } from "services"

export const getCookiesShortPaged = async (tenant, page, perPage) => {
    return await backendService.get(`/cookie/company-site?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getCookieById = async (id) => {
    return await backendService.get(`/cookie/company-site/${id}`)
}

export const deleteCookie = async (id) => {
    return await backendService.delete(`/cookie/company-site/${id}`)
}

export const addCookie = async (cookie) => {
    return await backendService.post(`/cookie/company-site`, cookie)
}

export const editCookie = async (id, cookie) => {
    return await backendService.put(`/cookie/company-site/${id}`, cookie)
}

export const getCookieCategoryById = async (id) => {
    return await backendService.get(`/cookie/cookie/${id}`)
}

export const getCookieConsentById = async (id) => {
    return await backendService.get(`/cookie/consent-log/${id}`)
}