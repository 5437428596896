import { useState } from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import { getAsmCertPaged, getCertCounter, getCertList } from 'services/asm-service';
import { useAuthTenant } from 'store/auth';
import { BlockTitle, CardCounter, ContainerLogo, ContentCardCounter, InfoCertBlock, TitleCardCounter } from '../styles';
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { DefaultTable } from 'Components/DefaultTable';
import { Pagination, Stack } from '@mui/material';
import { tableHeaderAsmCert } from './table-header-asm-cert';
import { Shimmer } from 'Components/PageLoader/styles';
import { PageLoaderList } from 'Components/PageLoader/List';

export const TabCert = () => {
  const [page, setPage] = useState(1);
  const selectedTenant = useSelector(useAuthTenant);

  const queries = useQueries([
    {
      queryKey: ['getCertCounter'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return null;

        const response = await getCertCounter(selectedTenant.slug);

        if (response?.data && response?.data?.certCounter) {
          return response?.data?.certCounter;
        }
        return 0;
      },
    },
    {
      queryKey: ['getCertList'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return null;

        const response = await getCertList(selectedTenant.slug);

        if (response?.data && response?.data?.certs) {
          return response?.data?.certs;
        }
        return [];
      },
    },
    {
      queryKey: ['getAsmCertPaged', page],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getAsmCertPaged(selectedTenant.slug, page, 6);

        if (result?.data?.data.length > 0) {
          return result?.data;
        }
        return {
          data: [],
          page,
          totalPages: 0,
        };
      },
    },
  ]);

  const [
    {
      data: certCounter,
      isLoading: certCounterLoading,
      // isError: certCounterError
    },
    {
      data: certs,
      isLoading: certsLoading,
      // isError: certsError
    },
    {
      data: pagedAsmCert,
      isLoading: asmCertLoading,
      // isError: asmCertError
    },
  ] = queries;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, qt } = payload[0].payload;
      return (
        <div style={{ backgroundColor: '#ffffff', padding: '5px', border: '1px solid #ccc' }}>
          <p>{`${name}: ${qt}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <CardCounter className={'mr-4'}>
        {!certCounterLoading && (
          <>
            <TitleCardCounter>Certificados</TitleCardCounter>
            <ContentCardCounter>
              {certCounter}
              <ContainerLogo>
                <img src="img/icons/flag.png" alt="logo de uma bandeira" />
              </ContainerLogo>
            </ContentCardCounter>
          </>
        )}
        {certCounterLoading && (
          <Shimmer
          // style={{ height: '250px', width: '500px' }}
          ></Shimmer>
        )}
      </CardCounter>
      <InfoCertBlock>
        {!certsLoading && (
          <>
            <BlockTitle>Emissores</BlockTitle>
            <ResponsiveContainer width="100%" height="100%" minHeight={200} minWidth={700}>
              <BarChart
                layout={'vertical'}
                width={700}
                height={300}
                data={certs}
                margin={{
                  top: 0,
                  right: 16,
                  left: 0,
                  bottom: 0,
                }}
                maxBarSize={certs.reduce((max, obj) => (obj.qa > max.qt ? obj : max), [0])[0].qt}
              >
                <XAxis type="number" domain={[0, 'dataMax + 1']} axisLine={false} />
                <YAxis dataKey={'name'} type="category" width={120} />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" />
                <Bar name={'Emissores'} dataKey="qt" barSize={24} fill="rgb(134,239,172)" />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
        {certsLoading && <Shimmer style={{ height: '200px', width: '700px' }}></Shimmer>}
      </InfoCertBlock>
      {!asmCertLoading && (
        <>
          <DefaultTable
            rows={tableHeaderAsmCert}
            cells={pagedAsmCert?.data || []}
            link=""
            showNoDataLine
            applyMB={pagedAsmCert?.totalPages < 1}
          />
          {pagedAsmCert?.totalPages > 0 && (
            <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginBlock: '16px', marginInline: 'auto' }}>
              <Pagination
                count={pagedAsmCert.totalPages}
                onChange={(_, selectedPage) => {
                  setPage(selectedPage);
                }}
                color="primary"
                page={page}
                showFirstButton
                showLastButton
              />
            </Stack>
          )}
        </>
      )}
      {asmCertLoading && <PageLoaderList />}
    </>
  );
};
