import { backendService } from "services"

export const getOfficesPaged = async (tenant, page, perPage) => {
    return await backendService.get(`/company/office/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getOfficeById = async (id) => {
    return await backendService.get(`/company/office/${id}`)
}

export const addOffice = async (data) => {
    return await backendService.post('/company/office', data)
}

export const editOffice = async (id, data) => {
    return await backendService.put(`/company/office/${id}`, data)
}

export const deleteOffice = async (id) => {
    return await backendService.delete(`/company/office/${id}`)
}