import { useState } from 'react';
import { useQuery } from 'react-query';
/** Components */
import { DefaultTable } from 'Components/DefaultTable';
import { Container } from '../../Components/Container';
import { Information } from 'Components/Information';
import { tableHeader } from './table-header.js';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { PageLoaderList } from 'Components/PageLoader/List';
/** Services */
import { getSystemsShortPaged } from 'services/systems-service';
/** State Managers */
import { useAuthTenant } from 'store/auth';
import { useSelector } from 'react-redux';
/** Third Lib Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { NoData } from 'Components/NoData';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { getUsersName } from 'services/users-service';
import { Card } from 'Components/Card';
import { Column, Row } from 'Components/Grid';
import { CustomTextField } from 'Components/Form/styles';
import { InputLoader } from 'Components/PageLoader/Input';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { callDeleteAction, callArchiveAction, callUnarchiveAction } from './actions';
import { ModalConfirm } from 'Components/ModalConfirm';

export const Systems = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [openUnarchiveModal, setOpenUnarchiveModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToArchive, setItemToArchive] = useState(null);
  const [itemToUnarchive, setItemToUnarchive] = useState(null);
  const [page, setPage] = useState(1);

  const { register, control, reset, watch, getValues } = useForm({
    defaultValues: {
      name: '',
      criticity: '',
      developedInHouse: 'Todos',
      saas: 'Todos',
      user: '',
      archived: false,
    },
  });

  const getContentSearch = () => {
    const dados = getValues();
    let queryString = `&archived=${dados.archived}&developedInHouse=${dados.developedInHouse}&saas=${dados.saas}`;
    if (dados.name !== '') {
      queryString += `&name=${dados.name}`;
    }
    if (dados.user !== '') {
      queryString += `&user=${dados.user}`;
    }
    if (dados.criticity !== '') {
      queryString += `&criticity=${dados.criticity}`;
    }

    return queryString;
  };

  const { isLoading: userLoading, data: users } = useQuery(['getUsers'], async () => {
    if (selectedTenant.slug === undefined || selectedTenant.slug === null) return [];
    const result = await getUsersName(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  const {
    isLoading,
    error,
    data: pagedSystems,
  } = useQuery(['getSystems', refetch, page], async () => {
    if (selectedTenant.slug === undefined || selectedTenant.slug === null) return [];

    const queryContent = getContentSearch();
    const result = await getSystemsShortPaged(selectedTenant.slug, page, 15, queryContent);
    if (result?.data.data.length > 0) {
      return result?.data;
    }
    return {
      data: [],
      page,
      totalPages: 0,
    };
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpenDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setItemToDelete(null);
  };

  const onArchive = item => {
    setItemToArchive(item);
    setOpenArchiveModal(true);
  };

  const onCloseArchiveModal = () => {
    setOpenArchiveModal(false);
    setItemToArchive(null);
  };

  const onUnarchive = item => {
    setItemToUnarchive(item);
    setOpenUnarchiveModal(true);
  };

  const onCloseUnarchiveModal = () => {
    setOpenUnarchiveModal(false);
    setItemToUnarchive(null);
  };

  const onConfirmDeleteModal = async () => {
    try {
      await callDeleteAction(itemToDelete, onCloseDeleteModal, refetch, setRefetch, SwalReact);
    } catch (error) {
      onCloseDeleteModal();
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const onConfirmArchiveModal = async () => {
    try {
      await callArchiveAction(itemToArchive, onCloseArchiveModal, refetch, setRefetch, SwalReact);
    } catch (error) {
      onCloseArchiveModal();
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const onConfirmUnarchiveModal = async () => {
    try {
      await callUnarchiveAction(itemToUnarchive, onCloseUnarchiveModal, refetch, setRefetch, SwalReact);
    } catch (error) {
      onCloseUnarchiveModal();
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  return (
    <Container title="Sistemas" breadcrumb="Mapeamento / Sistemas" showButtonAdd linkPage="/systems">
      <Information
        title={'Sistemas'}
        description={
          <p className="m-0">
            Diversas ferramentas são utilizadas no nosso dia a dia para a execução das nossas atividades, lembrando que
            esses sistemas são ferramentas online/SaaS (CRM, E-mail Marketing, Suítes de Produtividade, etc) ou
            ferramentas desenvolvidas internamente.
          </p>
        }
        style={{
          marginTop: '24px',
        }}
      />
      {!error && (
        <>
          <Card className="mb-2 mt-3">
            <Row className="mb-3">
              <Column xs={12} md={6}>
                <CustomTextField
                  {...register('name')}
                  label="Nome"
                  sx={{
                    minHeight: '40px',
                    maxHeight: '40px',
                    margin: '0 auto',
                    width: '100%',
                    ' > div': {
                      minHeight: '40px',
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Column>
              <Column xs={12} className="d-flex" md={3}>
                {!userLoading && (
                  <Controller
                    control={control}
                    name="user"
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <InputLabel id="user-label" shrink>
                          Responsável
                        </InputLabel>
                        <Select
                          labelId="user-label"
                          id="user"
                          value={value}
                          label="Responsável"
                          onChange={onChange}
                          notched
                          sx={{
                            minHeight: '40px',
                            maxHeight: '40px',
                            margin: '0 auto',
                            width: '100%',
                          }}
                        >
                          <MenuItem value="" key={-1}>
                            Selecione um responsável
                          </MenuItem>
                          {users
                            ?.map(user => user.name)
                            .map((item, index) => (
                              <MenuItem value={item} key={`user-${index}`}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
                {userLoading && (
                  <FormControl fullWidth sx={{ margin: '0 auto' }}>
                    <InputLabel id="user-label" shrink={true}>
                      Responsável
                    </InputLabel>
                    <InputLoader height={'40px'} />
                  </FormControl>
                )}
              </Column>
              <Column xs={12} md={3}>
                <Controller
                  control={control}
                  name="archived"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      value={value}
                      control={<Checkbox checked={value} onChange={onChange} name="archived" />}
                      label="Arquivado"
                      sx={{ marginLeft: 0 }}
                    />
                  )}
                />
              </Column>
            </Row>
            <Row className="mb-2">
              <Column xs={12} className="d-flex" md={3}>
                <Controller
                  control={control}
                  name="criticity"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabel id="criticity-label" shrink>
                        Criticidade
                      </InputLabel>
                      <Select
                        labelId="criticity-label"
                        id="criticity"
                        value={value}
                        label="Criticidade"
                        onChange={onChange}
                        notched
                        sx={{
                          minHeight: '40px',
                          maxHeight: '40px',
                          margin: '0 auto',
                          width: '100%',
                        }}
                      >
                        <MenuItem value="" key={-1}>
                          {' '}
                          Selecione uma criticidade{' '}
                        </MenuItem>
                        {['1', '2', '3', '4', '5'].map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {' '}
                            {item}{' '}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Column>
              <Column xs={12} md={3}>
                <Controller
                  control={control}
                  name="developedInHouse"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabel id="developed-in-house-label" shrink>
                        Desenvolvido internamente
                      </InputLabel>
                      <Select
                        labelId="developed-in-house-label"
                        id="developedInHouse"
                        value={value}
                        label="Desenvolvido internamente"
                        onChange={onChange}
                        notched
                        sx={{
                          minHeight: '40px',
                          maxHeight: '40px',
                          margin: '0 auto',
                          width: '100%',
                        }}
                      >
                        {['Todos', 'Sim', 'Não'].map(item => (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Column>
              <Column xs={12} md={2}>
                <Controller
                  control={control}
                  name="saas"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabel id="saas-label" shrink>
                        SAAS
                      </InputLabel>
                      <Select
                        labelId="saas-label"
                        id="saas"
                        value={value}
                        label="SAAS"
                        onChange={onChange}
                        notched
                        sx={{
                          minHeight: '40px',
                          maxHeight: '40px',
                          margin: '0 auto',
                          width: '100%',
                        }}
                      >
                        {['Todos', 'Sim', 'Não'].map(item => (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    reset();
                    setRefetch(!refetch);
                  }}
                >
                  Limpar
                </Button>
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  endIcon={<SearchOutlinedIcon />}
                  disabled={watch('typeFilter') === ''}
                  onClick={() => {
                    setRefetch(!refetch);
                  }}
                >
                  Filtrar
                </Button>
              </Column>
            </Row>
          </Card>
        </>
      )}
      {isLoading && <PageLoaderList></PageLoaderList>}
      {error && <div>Ocorreu um erro ...</div>}
      {!isLoading && !error && pagedSystems.data?.length === 0 && (
        <NoData description={'Não há Sistemas para exibir'} />
      )}
      {!isLoading && !error && pagedSystems.data?.length > 0 && (
        <div className="container">
          <DefaultTable
            rows={tableHeader}
            cells={pagedSystems.data}
            onClickDelete={onDelete}
            onClickArchive={onArchive}
            onClickUnarchive={onUnarchive}
            showArchive
            link="systems"
          />
          <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginTop: '16px' }}>
            <Pagination
              count={pagedSystems.totalPages}
              onChange={(_, selectedPage) => {
                setPage(selectedPage);
              }}
              color="primary"
              page={page}
              showFirstButton
              showLastButton
            />
          </Stack>
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.name} `}</strong> como sistema?
              </>
            }
            open={openDeleteModal}
            onClose={onCloseDeleteModal}
            onConfirm={onConfirmDeleteModal}
          />
          <ModalConfirm
            description={
              <>
                Tem certeza que deseja arquivar o Sistema <strong>{` ${itemToArchive?.name}`}</strong>?
              </>
            }
            open={openArchiveModal}
            onClose={onCloseArchiveModal}
            onConfirm={onConfirmArchiveModal}
          />
          <ModalConfirm
            description={
              <>
                Tem certeza que deseja desarquivar o Sistema
                <strong>{` ${itemToUnarchive?.name}`}</strong>?
              </>
            }
            open={openUnarchiveModal}
            onClose={onCloseUnarchiveModal}
            onConfirm={onConfirmUnarchiveModal}
          />
        </div>
      )}
    </Container>
  );
};
