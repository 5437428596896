import { backendService } from "services"

export const getUsersGroups = async (tenant) => {
    return await backendService.get(`/user/group/by-tenant/${tenant}`)
}

export const getUsersGroupsPaged = async (tenant, page, perPage) => {
    return await backendService.get(`/user/group/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getUsersGroupById = async (id) => {
    return await backendService.get(`/user/group/${id}`)
}

export const addUsersGroup = async (userGroup) => {
    return await backendService.post('/user/group', userGroup)
}

export const editUsersGroup = async (id, userGroup) => {
    return await backendService.put(`/user/group/${id}`, userGroup)
}

export const deleteUsersGroup = async (id) => {
    return await backendService.delete(`/user/group/${id}`)
}