import { backendService } from "services"

export const getRopas = async (tenant) => {
    return await backendService.get(`/mapping/reports/ropa?companyKey=${tenant}`)
}

export const getRopasPaged = async (tenant, page, perPage) => {
    return await backendService.get(`/mapping/reports/ropa/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getRopasShortPaged = async (tenant, page, perPage) => {
    return await backendService.get(`/mapping/reports/ropa/short-data-paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getRopaById = async (id) => {
    return await backendService.get(`/mapping/reports/ropa/${id}`)
}

export const addRopa = async (ropa) => {
    return await backendService.post('/mapping/reports/ropa', ropa)
}

export const deleteRopa = async (id) => {
    return await backendService.delete(`/mapping/reports/ropa/${id}`)
}

export const getExportFileRopa = async (ropa) => {
    return await backendService.post('/mapping/reports/ropa/export',
      ropa,
      {
          responseType: 'blob',
      })
}