import { backendService } from "services"

export const getDatas = async (tenant) => {
    return await backendService.get(`/mapping/data?companyKey=${tenant}`)
}

export const getDatasPaged = async (tenant, page, perPage) => {
    return await backendService.get(`/mapping/data/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getDatasNames = async (tenant) => {
    return await backendService.get(`/mapping/data/names?companyKey=${tenant}`)
}

export const getDataById = async (id) => {
    return await backendService.get(`/mapping/data/${id}`)
}

export const addData = async (data) => {
    return await backendService.post('/mapping/data', data)
}

export const editData = async (id, data) => {
    return await backendService.put(`/mapping/data/${id}`, data)
}

export const deleteData = async (id) => {
    return await backendService.delete(`/mapping/data/${id}`)
}