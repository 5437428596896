import { backendService } from "services"

export const getAreas = async (tenant) => {
    return await backendService.get(`/mapping/area?companyKey=${tenant}`)
}

export const getAreasPaged = async (tenant, page, perPage) => {
    return await backendService.get(`/mapping/area/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getAreasShort = async (tenant) => {
    return await backendService.get(`/mapping/area/short-data?companyKey=${tenant}`)
}

export const getAreasShortPaged = async (tenant, page, perPage, queryContent) => {
    return await backendService.get(`/mapping/area/short-data-paged?companyKey=${tenant}&page=${page}&perPage=${perPage}${queryContent}`)
}

export const getAreasNames = async (tenant) => {
    return await backendService.get(`/mapping/area/names?companyKey=${tenant}`)
}

export const getAreaById = async (id) => {
    return await backendService.get(`/mapping/area/${id}`)
}

export const getAreaBoundsById = async (id) => {
    return await backendService.get(`/mapping/area/${id}/bounds`)
}

export const addArea = async (area) => {
    return await backendService.post('/mapping/area', area)
}

export const editArea = async (id, area) => {
    return await backendService.put(`/mapping/area/${id}`, area)
}

export const deleteArea = async (id) => {
    return await backendService.delete(`/mapping/area/${id}`)
}

export const archiveArea = async (id) => {
    return await backendService.post(`/mapping/area/archive/${id}`)
}

export const unarchiveArea = async (id) => {
    return await backendService.post(`/mapping/area/unarchive/${id}`)
}