import { backendService } from "services"

export const getAssets = async (tenant) => {
    return await backendService.get(`/mapping/asset?companyKey=${tenant}`)
}
export const getAssetsPaged = async (tenant, page, perPage) => {
    return await backendService.get(`/mapping/asset/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getAssetsShort = async (tenant) => {
    return await backendService.get(`/mapping/asset/short-data?companyKey=${tenant}`)
}

export const getAssetsShortPaged = async (tenant, page, perPage, queryContent) => {
    return await backendService.get(`/mapping/asset/short-data-paged?companyKey=${tenant}&page=${page}&perPage=${perPage}${queryContent}`)
}

export const getAssetsNames = async (tenant) => {
    return await backendService.get(`/mapping/asset/names?companyKey=${tenant}`)
}

export const getAssetById = async (id) => {
    return await backendService.get(`/mapping/asset/${id}`)
}

export const getAssetBoundsById = async (id) => {
    return await backendService.get(`/mapping/asset/${id}/bounds`)
}

export const addAsset = async (asset) => {
    return await backendService.post('/mapping/asset', asset)
}

export const editAsset = async (id, asset) => {
    return await backendService.put(`/mapping/asset/${id}`, asset)
}

export const deleteAsset = async (id) => {
    return await backendService.delete(`/mapping/asset/${id}`)
}

export const archiveAsset = async (id) => {
    return await backendService.post(`/mapping/asset/archive/${id}`)
}

export const unarchiveAsset = async (id) => {
    return await backendService.post(`/mapping/asset/unarchive/${id}`)
}