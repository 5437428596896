import { Box, Chip, Tab, Tabs } from '@mui/material';
import { Container } from 'Components/Container';
import { Column, Row } from 'Components/Grid';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnEdit, LinkEdit, RowExhibition } from './styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { deleteProcessMapping, getProcessById, importProcessDataMapping } from 'services/processes-service';
import { DefaultTable } from 'Components/DefaultTable';
import { PageLoaderShow } from 'Components/PageLoader/Show';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import FileUploader from 'Components/FileUploader';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { tableHeader } from './table-header';
import { ButtonForm } from 'Components/Form/styles';

const TabPanel = props => {
  const { children, value, index, id, ariaLabel, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={id} aria-labelledby={ariaLabel} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const ProcessesShow = () => {
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabPosition, setTabPosition] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemDelete, setItemDelete] = useState(null);
  const [itemDeleteId, setItemDeleteId] = useState(null);
  const [loagingUpload, setLoadingUpload] = useState(false);
  const {
    isLoading,
    error,
    data: process,
  } = useQuery(['getProcessById', refresh], async () => {
    const dataProcess = await getProcessById(id);
    return dataProcess.status === 200 ? dataProcess.data : null;
  });

  const onClickEdit = () => {
    navigate('/processes/edit/' + id);
  };

  const onDelete = (item, itemId) => {
    setItemDelete(item);
    setItemDeleteId(itemId);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setItemDelete(null);
    setItemDeleteId(null);
  };

  const onConfirm = async () => {
    try {
      const result = await deleteProcessMapping(id, itemDeleteId);
      if (result.status === 200) {
        onClose();
        setRefresh(!refresh);
      } else {
        throw new Error('Ocorreu um erro ao tentar remover o Dado do Processo!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const importDataMapping = useMutation(
    content => {
      return importProcessDataMapping(id, {
        file: content,
      });
    },
    {
      onError: error => {
        // An error happened!
        setLoadingUpload(false);
        SwalReact.fire({
          title: 'Erro!',
          text: error.response.data.message ?? error.message,
          icon: 'error',
        });
      },
      onSuccess: data => {
        setLoadingUpload(false);
        if (data.status === 200) {
          SwalReact.fire({
            title: 'Sucesso!',
            text: 'Arquivo importado com sucesso!',
            icon: 'success',
            didClose: () => {
              setRefresh(!refresh);
            },
          });
        }
      },
    },
  );

  return (
    <>
      {!isLoading && !error && (
        <Container
          title={`${process?.name}`}
          breadcrumb={`Mapeamento / Processos / ${process?.name}`}
          linkPage="/processes"
          actionButton={
            <FileUploader
              sentAction={content => importDataMapping.mutate(content)}
              buttonActionName="Importar CSV"
              buttonSentActionName="Importando"
              loading={loagingUpload}
              changeStatus={() => {
                setLoadingUpload(!loagingUpload);
              }}
            />
          }
        >
          <Box sx={{ width: '100%' }} className="container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabPosition}
                onChange={(data, newValue) => setTabPosition(newValue)}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Detalhes"
                  id="tab-details"
                  aria-controls="tabpanel-details"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Vínculos"
                  id="tab-links"
                  aria-controls="tabpanel-links"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Dados"
                  id="tab-datas"
                  aria-controls="tabpanel-datas"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
              </Tabs>
            </Box>
            <TabPanel value={tabPosition} index={0} id="tabpanel-details" ariaLabel="tab-details">
              <RowExhibition>
                <Column xs={3}>Nome</Column>
                <Column>{process.name}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Criticidade</Column>
                <Column>{process.criticity}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Responsável</Column>
                <Column>{process.user}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>TAGs</Column>
                <Column>
                  {process.tags?.map(tag => (
                    <Chip
                      key={tag}
                      label={tag}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Descrição</Column>
                <Column>{process.description}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Descrição do Fluxo</Column>
                <Column>{process.descriptionDataFlow}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>DPIA</Column>
                <Column>{process?.dpia ? 'Sim' : 'Não'}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>LIA</Column>
                <Column>{process?.lia ? 'Sim' : 'Não'}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Tratamento de dados de crianças, adolescentes e idosos</Column>
                <Column>{process?.dataTreatmentFromChildrenTeenagerElderly ? 'Sim' : 'Não'}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
            <TabPanel value={tabPosition} index={1} id="tabpanel-links" ariaLabel="tab-links">
              <RowExhibition>
                <Column xs={3}>Areas vinculados</Column>
                <Column>
                  {process?.areas?.map(area => (
                    <Chip
                      key={area.name}
                      label={area.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                        display: area.archived ? 'none' : '',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Sistemas vinculados</Column>
                <Column>
                  {process?.systems?.map(system => (
                    <Chip
                      key={system.name}
                      label={system.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                        display: system.archived ? 'none' : '',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Ativos</Column>
                <Column>
                  {process?.assets?.map(asset => (
                    <Chip
                      key={asset.name}
                      label={asset.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                        display: asset.archived ? 'none' : '',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
            <TabPanel value={tabPosition} index={2} id="tabpanel-datas" ariaLabel="tab-datas">
              <Row justifyContent="end" alignItems="center">
                <Column xs={2} justifyContent="center" className="d-flex mb-2">
                  <ButtonForm
                    label="Novo"
                    variant="button"
                    onClick={() => {
                      navigate(`/processes/edit/${id}/mapping`);
                    }}
                  />
                </Column>
              </Row>
              <DefaultTable
                rows={tableHeader}
                id={id}
                cells={process.dataMapping}
                onClickShow={mappingId => {
                  navigate(`/processes/show/${id}/mapping/${mappingId}`);
                }}
                onClickEdit={mappingId => {
                  navigate(`/processes/edit/${id}/mapping/${mappingId}`);
                }}
                onClickDelete={(item, mappingId) => {
                  onDelete(item, mappingId);
                }}
                link="processes"
              />
              <ModalConfirmDelete
                description={
                  <>
                    Tem certeza que deseja excluir <strong>{` ${itemDelete?.name} `}</strong> como Dado do Processo?
                  </>
                }
                open={open}
                onClose={onClose}
                onConfirm={onConfirm}
              />
            </TabPanel>
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
