import { useState } from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import {
  getAsmVulnerabilityPaged,
  getSeverityList,
  getVulnerabilityCounter,
  getVulnerabilityList,
} from 'services/asm-service';
import { useAuthTenant } from 'store/auth';
import {
  BlockTitle,
  CardCounter,
  ContainerLogo,
  ContentCardCounter,
  InfoVulBlock,
  InfoVulFullBlock,
  TitleCardCounter,
} from '../styles';
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, PieChart, Pie, Cell } from 'recharts';
import { DefaultTable } from 'Components/DefaultTable';
import { Pagination, Stack } from '@mui/material';
import { Shimmer } from 'Components/PageLoader/styles';
import { PageLoaderList } from 'Components/PageLoader/List';
import { tableHeaderAsmVul } from './table-header-asm-vulnerability';

export const TabVulnerability = () => {
  const [page, setPage] = useState(1);
  const selectedTenant = useSelector(useAuthTenant);

  const queries = useQueries([
    {
      queryKey: ['getVulnerabilityCounter'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return null;

        const response = await getVulnerabilityCounter(selectedTenant.slug);

        if (response?.data && response?.data?.vulnerabilityCounter) {
          return response?.data?.vulnerabilityCounter;
        }
        return 0;
      },
    },
    {
      queryKey: ['getSeverityList'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return null;

        const response = await getSeverityList(selectedTenant.slug);

        if (response?.data && response?.data?.severities) {
          return response?.data?.severities;
        }
        return [];
      },
    },
    {
      queryKey: ['getVulnerabilityList'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return null;

        const response = await getVulnerabilityList(selectedTenant.slug);

        if (response?.data && response?.data?.vulnerabilities) {
          return response?.data?.vulnerabilities;
        }
        return [];
      },
    },
    {
      queryKey: ['getAsmVulnerabilityPaged', page],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getAsmVulnerabilityPaged(selectedTenant.slug, page, 6);

        if (result?.data?.data.length > 0) {
          return result?.data;
        }
        return {
          data: [],
          page,
          totalPages: 0,
        };
      },
    },
  ]);

  const [
    {
      data: vulnerabilityCounter,
      isLoading: vulnerabilityCounterLoading,
      // isError: vulnerabilityCounterError
    },
    {
      data: severities,
      isLoading: severityLoading,
      // isError: severityError
    },
    {
      data: vulnerabilities,
      isLoading: vulnerabilityLoading,
      // isError: vulnerabilityError
    },
    {
      data: pagedAsmVulnerability,
      isLoading: asmVulnerabilityLoading,
      // isError: asmVulnerabilityError
    },
  ] = queries;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, qt } = payload[0].payload;
      return (
        <div style={{ backgroundColor: '#ffffff', padding: '5px', border: '1px solid #ccc' }}>
          <p>{`${name}: ${qt}`}</p>
        </div>
      );
    }
    return null;
  };

  const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#AF19FF',
    '#19FF92',
    '#FF1942',
    '#1994FF',
    '#FFDF19',
    '#C219FF',
  ];

  return (
    <>
      <CardCounter className={'mr-4'}>
        {!vulnerabilityCounterLoading && (
          <>
            <TitleCardCounter>Vulnerabilidades</TitleCardCounter>
            <ContentCardCounter>
              {vulnerabilityCounter}
              <ContainerLogo>
                <img src="img/icons/globe.png" alt="logo de um globo" />
              </ContainerLogo>
            </ContentCardCounter>
          </>
        )}
        {vulnerabilityCounterLoading && (
          <Shimmer
          // style={{ height: '250px', width: '500px' }}
          ></Shimmer>
        )}
      </CardCounter>
      <InfoVulBlock>
        {!severityLoading && (
          <>
            <BlockTitle>Severidades</BlockTitle>
            <ResponsiveContainer width="100%" height="100%" minHeight={200} minWidth={700}>
              <PieChart
                width={700}
                height={300}
                margin={{
                  top: 0,
                  right: 16,
                  left: 0,
                  bottom: 0,
                }}
              >
                <Pie
                  cx="50%"
                  cy="50%"
                  data={severities}
                  dataKey={'qt'}
                  isAnimationActive={true}
                  nameKey={'name'}
                  innerRadius={50}
                >
                  {severities.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend verticalAlign="top" />
              </PieChart>
            </ResponsiveContainer>
          </>
        )}
        {severityLoading && <Shimmer style={{ height: '200px', width: '700px' }}></Shimmer>}
      </InfoVulBlock>
      <InfoVulFullBlock>
        {!vulnerabilityLoading && (
          <>
            <BlockTitle>Vulnerabilidades</BlockTitle>
            <ResponsiveContainer width="100%" height="100%" minHeight={200} minWidth={700}>
              <BarChart
                layout={'vertical'}
                width={700}
                height={300}
                data={vulnerabilities}
                margin={{
                  top: 0,
                  right: 16,
                  left: 0,
                  bottom: 0,
                }}
                maxBarSize={vulnerabilities.reduce((max, obj) => (obj.qt > max.qt ? obj : max), [0])[0].qt}
              >
                <XAxis type="number" domain={[0, 'dataMax + 1']} axisLine={false} />
                <YAxis dataKey={'name'} type="category" width={120} />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" />
                <Bar name={'Vulnerabilidades'} dataKey="qt" barSize={24} fill="rgb(134,239,172)" />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
        {vulnerabilityLoading && <Shimmer style={{ height: '200px', width: '700px' }}></Shimmer>}
      </InfoVulFullBlock>
      {!asmVulnerabilityLoading && (
        <>
          <DefaultTable
            rows={tableHeaderAsmVul}
            cells={pagedAsmVulnerability?.data || []}
            showNoDataLine
            applyMB={pagedAsmVulnerability?.totalPages < 1}
          />
          {pagedAsmVulnerability?.totalPages > 0 && (
            <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginBlock: '16px', marginInline: 'auto' }}>
              <Pagination
                count={pagedAsmVulnerability?.totalPages || 0}
                onChange={(_, selectedPage) => {
                  setPage(selectedPage);
                }}
                color="primary"
                page={page}
                showFirstButton
                showLastButton
              />
            </Stack>
          )}
        </>
      )}
      {asmVulnerabilityLoading && <PageLoaderList />}
    </>
  );
};
