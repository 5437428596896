import { backendService } from "services";

export const getAsmAssetPaged = async (slug, page, perPage) => {
  return await backendService.get(`/asm/asset-paged?org=${slug}&page=${page}&perPage=${perPage}`)
}

export const getAsmCertPaged = async (slug, page, perPage) => {
  return await backendService.get(`/asm/cert-paged?org=${slug}&page=${page}&perPage=${perPage}`)
}

export const getAsmVulnerabilityPaged = async (slug, page, perPage) => {
  return await backendService.get(`/asm/vulnerability-paged?org=${slug}&page=${page}&perPage=${perPage}`)
}

export const getCdnList = async (slug) => {
  return await backendService.get(`/asm/cdn-list/${slug}`)
}

export const getWafList = async (slug) => {
  return await backendService.get(`/asm/waf-list/${slug}`)
}

export const getCertList = async (slug) => {
  return await backendService.get(`/asm/cert-list/${slug}`)
}

export const getCertCounter = async (slug) => {
  return await backendService.get(`/asm/cert-counter/${slug}`)
}

export const getSeverityList = async (slug) => {
  return await backendService.get(`/asm/severity-list/${slug}`)
}

export const getVulnerabilityList = async (slug) => {
  return await backendService.get(`/asm/vulnerability-list/${slug}`)
}

export const getVulnerabilityCounter = async (slug) => {
  return await backendService.get(`/asm/vulnerability-counter/${slug}`)
}

export const canScan = async (slug) => {
  return await backendService.get(`/asm/can-scan/${slug}`)
}

export const scanBySlug = async (slug) => {
  const data = {
    tenant: slug,
  }
  return await backendService.post(`/asm/scan/`, data)
}