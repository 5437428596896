import { archiveProcess, deleteProcess, unarchiveProcess } from "services/processes-service";

export const callDeleteAction = async (itemToDelete, onClose, refetch, setRefetch, SwalReact) => {
  const result = await deleteProcess(itemToDelete?._id);
  if (result.status === 200 || result.status === 204) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Processo removido com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar remover o Processo!');
  }
};

export const callArchiveAction = async (itemToArchive, onClose, refetch, setRefetch, SwalReact) => {
  const result = await archiveProcess(itemToArchive?._id);
  if (result.status === 200) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Processo arquivado com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar arquivar o Processo!');
  }
};

export const callUnarchiveAction = async (itemToUnarchive, onClose, refetch, setRefetch, SwalReact) => {
  const result = await unarchiveProcess(itemToUnarchive?._id);
  if (result.status === 200) {
    onClose();
    setRefetch(!refetch);
    SwalReact.fire({
      title: 'Sucesso!',
      text: 'Processo desarquivado com sucesso',
      icon: 'success',
    });
  } else {
    throw new Error('Ocorreu um erro ao tentar desarquivar o Processo!');
  }
};