import { backendService } from "services"

export const getAuditLogPaged = async (
    tenant,
    page,
    perPage,
    typeFilter,
    searchContent,
    startDate,
    endDate
) => {
    let dateFilter = '';
    if (startDate && endDate) {
      dateFilter = `&startDate=${startDate}&endDate=${endDate}`
    } else if (startDate && !endDate) {
      dateFilter = `&startDate=${startDate}`
    } else if (!startDate && endDate) {
      dateFilter = `&endDate=${endDate}`
    }
    let searchFilter = '';
    if (searchContent) {
      searchFilter = `&typeFilter=${typeFilter}&searchContent=${searchContent}`;
    }
    return await backendService.get(`/user/audit-log?companyKey=${tenant}&page=${page}&perPage=${perPage}${searchFilter}${dateFilter}`)
}